.box {
  height: 100px;
  width: 100px;
  background-color: lightblue;
}

.edit-cell {
  height: 55px;
  width: 70px;

  .trigger {
    display: none;
    cursor: pointer;
  }
}

.transaction-table-row {
  &:hover {
    background-color: hsl(0, 0%, 97%);

    .trigger {
      display: unset;
    }
  }
}

// LOGIN PAGE

.password-input-wrapper {
  display: flex;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;

  .password-view-toggle {
    width: 50px;
    border: none;
  }

  input {
    width: 353px;
    border: none;
    font-size: 16px;
  }
}

$border: #d1d7e3;
$success: #69dd95;
$textColor: #797c86;
$placeholderColor: #c7c8cc;
$inputBorder: 0;
$inputShadow: 0 4px 12px -2px rgba(#6b75a1, 0.16);

.email-input-wrapper {
  .email-input {
    position: relative;
    width: 100%;
    border-radius: 0.25rem;
    border: 1px solid #ced4da;

    input {
      outline: none;
      width: 100%;
      font-size: 16px;
      border: $inputBorder;
      border-radius: 4px;
      line-height: 24px;
      padding: 8px 36px 8px 14px;
      // box-shadow: $inputShadow;
      color: $textColor;
      &::-webkit-input-placeholder {
        color: $placeholderColor;
      }
      &:-moz-placeholder {
        color: $placeholderColor;
      }
      &::-moz-placeholder {
        color: $placeholderColor;
      }
      &:-ms-input-placeholder {
        color: $placeholderColor;
      }
    }
    svg {
      position: absolute;
      z-index: 1;
      right: 14px;
      top: 50%;
      width: 20px;
      height: 20px;
      fill: none;
      margin: -10px 0 0 0;
      polyline,
      path {
        stroke: $border;
        stroke-width: 1.4;
        stroke-linecap: round;
        stroke-linejoin: round;
        transition: stroke 0.3s ease 0s;
      }
      path {
        stroke-dasharray: 64;
        stroke-dashoffset: 127;
        transition: stroke-dasharray 0.8s ease 0.8s,
          stroke-dashoffset 0.8s ease 0.5s;
      }
      polyline {
        stroke-dasharray: 18;
        stroke-dashoffset: 18;
        transition: stroke-dashoffset 0.5s ease 0s;
      }
    }
    &.success {
      svg {
        polyline,
        path {
          stroke: $success;
        }
        path {
          stroke-dasharray: 46;
          stroke-dashoffset: 150;
          transition: stroke-dasharray 0.6s ease 0s,
            stroke-dashoffset 0.8s ease 0.3s, stroke 0.3s ease 0.6s;
        }
        polyline {
          stroke-dashoffset: 0;
          transition: stroke-dashoffset 0.5s ease 0.6s, stroke 0.3s ease 0.6s;
        }
      }
    }
  }
}

// Bank accounts

// Debit card carousel card

.credit-card-wrapper {
  width: 320px;
  height: 190px;
  -webkit-perspective: 600px;
  -moz-perspective: 600px;
  perspective: 600px;
}

.card__part {
  box-shadow: 1px 1px #aaa3a3;
  top: 0;
  position: absolute;
  z-index: 1000;
  left: 0;
  display: inline-block;
  width: 320px;
  height: 190px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 8px;

  -webkit-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.credit-card-wrapper .square-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.credit-card-wrapper .square-btn.delete-btn {
  position: absolute;
  top: 10px;
  right: 50px;
  font-size: 18px;
  padding-top: 3px;
}

.credit-card-wrapper.no-eye {
  .square-btn {
    display: none;
  }
}

.credit-card-wrapper.bg1 .card__part {
  background-image: linear-gradient(
    to right bottom,
    #fd696b,
    #fa616e,
    #f65871,
    #f15075,
    #ec4879
  );
}

.credit-card-wrapper.bg2 .card__part {
  background-color: #f39f86;
  background-image: linear-gradient(315deg, #f39f86 0%, #f9d976 74%);
}

.credit-card-wrapper.bg3 .card__part {
  background-color: #7f53ac;
  background-image: linear-gradient(315deg, #7f53ac 0%, #647dee 74%);
}

.credit-card-wrapper.bg4 .card__part {
  background-color: #08e1ae;
  background-image: linear-gradient(315deg, #08e1ae 0%, #98de5b 74%);
}

.credit-card-wrapper.bg5 .card__part {
  background-color: #045de9;
  background-image: linear-gradient(315deg, #045de9 0%, #09c6f9 74%);
}

.credit-card-wrapper.bg1.map > .card__part {
  background-image: url("../../images/custom/world-map-2.png"),
    linear-gradient(
      to right bottom,
      #fd696b,
      #fa616e,
      #f65871,
      #f15075,
      #ec4879
    );
}

.credit-card-wrapper.bg2.map > .card__part {
  background-image: url("../../images/custom/world-map-2.png"),
    linear-gradient(315deg, #f39f86 0%, #f9d976 74%);
}

.credit-card-wrapper.bg3.map > .card__part {
  background-image: url("../../images/custom/world-map-2.png"),
    linear-gradient(315deg, #7f53ac 0%, #647dee 74%);
}

.credit-card-wrapper.bg4.map > .card__part {
  background-image: url("../../images/custom/world-map-2.png"),
    linear-gradient(315deg, #08e1ae 0%, #98de5b 74%);
}

.credit-card-wrapper.bg5.map > .card__part {
  background-image: url("../../images/custom/world-map-2.png"),
    linear-gradient(315deg, #045de9 0%, #09c6f9 74%);
}

.card__front {
  padding: 25px;
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
}

.card__back {
  padding: 18px 0;
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
}

.card__black-line {
  margin-top: 5px;
  height: 38px;
  background-color: #303030;
}

.card__logo {
  height: 16px;
}

.card__front-logo {
  // position: absolute;
  // top: 18px;
  // right: 18px;
}

.card__square {
  border-radius: 5px;
  height: 30px;
}

.card_numer {
  display: block;
  width: 100%;
  word-spacing: 4px;
  font-size: 20px;
  letter-spacing: 2px;
  color: #fff;
  // text-align: center;
  margin-bottom: 20px;
  margin-top: 33px;
}

.card__label {
  font-size: 10px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 1px;
}

.card__info {
  margin-bottom: 0;
  margin-top: 5px;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.card__back-content {
  padding: 15px 15px 0;
}
.card__secret--last {
  color: #303030;
  text-align: right;
  margin: 0;
  font-size: 14px;
}

.card__secret {
  padding: 5px 12px;
  background-color: #fff;
  position: relative;
}

.card__secret:before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  height: calc(100% + 6px);
  width: calc(100% - 42px);
  border-radius: 4px;
  background: repeating-linear-gradient(
    45deg,
    #ededed,
    #ededed 5px,
    #f9f9f9 5px,
    #f9f9f9 10px
  );
}

.card__back-logo {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.card__back-square {
  position: absolute;
  bottom: 15px;
  left: 15px;
}

.credit-card-wrapper.hover:hover .card__front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

.credit-card-wrapper.hover:hover .card__back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
}

.credit-card-slider-wrapper {
  max-width: 1100px;
  margin: 0 auto;
}

.credit-card-slider {
  max-width: 100%;
  margin: 40px 0;

  .slick-list {
    border-radius: 5px;

    .slick-slide {
      display: flex;
      justify-content: center;
    }
  }

  .slick-dots {
    bottom: -40px;
  }

  .slick-arrow.slick-next::before,
  .slick-arrow.slick-prev::before {
    color: rgb(192, 192, 192);
  }
}

.square-btn {
  height: 30px;
  width: 30px;
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.8);
  outline: none;
  border: none;
  border-radius: 5px;
  transition-duration: 300ms;

  &:hover {
    transform: scale(1.2);
  }
}

.bank-page-card {
  background-color: #e2edf1;

  .card-body {
    padding: 15px 35px;

    p:last-child {
      margin: 0;
    }
  }
}

.datepicker-dropdown-menu {
  // left: -520px !important;
  left: unset !important;
  right: 0 !important;
}

.date-picker-toggle {
  .date-text {
    transition: width 300ms;
    transition-duration: 500ms;
    position: relative;
    width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-align: right;
    color: #c8c8c8;
    word-spacing: 1px;
    // background-color: lightcoral;

    span {
      color: #fff;
      font-weight: 700;
      letter-spacing: 1px;
    }
  }

  &:hover {
    .date-text {
      width: 310px;
    }
  }
}

.legend-container {
  // background-color: lightblue;
  display: flex;
  justify-content: center;

  .wrapper {
    display: flex;
    align-items: center;

    &:first-child {
      margin-right: 20px;
    }

    .box {
      height: 20px;
      width: 20px;
      border-radius: 3px;
      margin-right: 5px;
    }

    p {
      margin: 0;
      padding: 0;
      padding-top: 1px;
    }
  }
}

.hide-graph-logo {
  position: absolute;
  height: 21px;
  width: 57px;
  background-color: white;
  bottom: 19px;
}

// Loader

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100vh;
  width: 100%;
  background-color: #ffffff;

  display: grid;
  align-items: center;

  h2 {
    text-align: center;
  }

  //   #wrapper{
  //     position:relative;
  //     /*background:#333;*/
  //     height:100%;
  // }

  .profile-main-loader {
    width: 100%;
    transform: scale(0.6);
  }

  .profile-main-loader .loader {
    position: relative;
    margin: 0px auto;
    width: 200px;
    height: 200px;
  }
  .profile-main-loader .loader:before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  .circular-loader {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    height: 100%;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
  }

  .loader-path {
    stroke-width: 3;
    stroke-dasharray: 150, 200;
    stroke-dashoffset: -10;
    -webkit-animation: dash 1.5s ease-in-out infinite,
      color 6s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
@-webkit-keyframes color {
  0% {
    stroke: #556ee6;
  }
  40% {
    stroke: #556ee6;
  }
  66% {
    stroke: #556ee6;
  }
  80%,
  90% {
    stroke: #556ee6;
  }
}
@keyframes color {
  0% {
    stroke: #556ee6;
  }
  40% {
    stroke: #556ee6;
  }
  66% {
    stroke: #556ee6;
  }
  80%,
  90% {
    stroke: #556ee6;
  }
}
